#root {
  width: 90%;
}

@media only screen and (max-width: 905px) {

  .volo-About-about ul.volo-About-biography {
    width: calc(100% - 8px);
    grid-template-columns: 100%;
    grid-gap: 20px;
  }
  .volo-About-about ul.volo-About-biography .volo-About-volologo {
    min-height: 400px;
  }


}

@media only screen and (max-width: 768px) {

  .volo-About-about ul {
    width: calc(100%);
    grid-template-columns: 100%;
  }
  .wrapper {
    width: calc(100%);
    grid-template-columns: 100%;
  }
  .wrapper-invoice {
    width: calc(100% - 8px);
  }
}
@media only screen and (max-width: 500px) {
  h2 {
    font-size: 20px;
    line-height: 26px
  }
  .volo-About-about {
    padding: 16px;
  }
  form label {
    font-size: 20px;
    line-height: 26px;
  }
  .wrapper-invoice {
    width: calc(100% - 0px);
    grid-template-columns: 100%;
  }

}
