.volo-DateSelect {
  display: grid;
  align-items: center;
  justify-content: start;
  grid-template-columns: auto auto auto auto;
}

.volo-DateSelect-text {
  font-size: 30px;
  font-weight: 600;
  margin-left: 0px;
}

.react-datepicker-wrapper div input {
  padding: 10px;
  border-radius: 26px;
  border: none;
  text-align: center;
  outline: none !important;
  width: 142px;
  height: 48px;
  margin-right: 20px;
  margin-left: 20px;
  font-weight: 500;
}

.react-datepicker-wrapper div input::placeholder {
  font-size: 17px;
  font-weight: 500;
  color: var(--color-primarytext);
}

@media only screen and (max-width: 900px) {
  .volo-DateSelect {
    justify-content: center;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr;
    text-align: center;
  }
  .react-datepicker-wrapper div input {
    width:100%;
    margin:20px 0 20px 0;
  }
}
