body {
  color: white;
  background-color: var(--color-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  font-size: 17px;
  line-height: 24px;
  --color-white: #FFFFFF;
  --color-accent: #00AF6E;
  --color-gray: #f4f4f4;
  --color-secondarytext: #65696C;
  --color-primarytext: #242A30;
  --color-bg: #242A30;
  --color-darkgray: #999999;
  --color-black: #222222;
}

p strong {
  font-weight: 800;
}

p {
  color: var(--color-secondarytext);
}

h1 {
  margin: 16px 0px 36px 0px;
}

h3 {
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 0px;
  color: var(--color-accent);
}
h4 {
  color: var(--color-primarytext);
  font-size:24px;
  margin: 12px 0px;
}

@media only screen and (max-width: 900px) {
  body {
    margin-top: 16px;
  }
}
