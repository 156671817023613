.volo-Confirmation {
  border-radius: 8px;
  color: var(--color-primarytext);
  background-color: white;
  padding: 20px;
}

.volo-Confirmation-Logo {
  object-fit: scale-down;
  width: 60px;
}

.volo-Confirmation-Header {
  display: flex;
  grid-row: 1;
  grid-column: 1/3;
  align-items: center;
  margin-bottom: 10px;
}

.volo-Confirmation-Title {
  font-size: 20px;
  margin-left: 20px;
}

.volo-Confirmation-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.volo-Confirmation-options {
  display: flex;
  flex-direction: column;
}

.volo-Confirmation-confirm-message {
  font-size: 30px;
  line-height: 32px;
  font-weight: 700;
  margin: 30px 0;
}

.volo-Confirmation-title {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0;
}

.volo-Confirmation-button {
  margin: 1vh 0;
  padding: 1vh;
  border-radius: 52px;
  outline: none !important;
  border-color: transparent;
  font-weight: 700;
  background-color: var(--color-gray)
}

@media only screen and (max-width: 900px) {
  .volo-Confirmation-container {
    display: block;
  }
}
