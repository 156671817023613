.volo-Footer-copyright {
  justify-content: center;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  text-decoration: underline;
  margin-right: 16px;
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 10px;
  color:var(--color-gray);
}
.volo-Footer-link {
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  text-decoration: underline;
  color: white;
}
