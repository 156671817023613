.volo-PassengerDetails {
  display: grid;
  grid-row: 2/3;
}

.volo-PassengerDetails-title {
  font-size: 30px;
  line-height: 32px;
  font-weight: 700;
  margin: 30px 0;
}

.volo-PassengerDetails-subtitle{
  font-size: 20px;
  color: var(--color-darkgray);
  margin-bottom: 20px;
}

.volo-PassengerDetails-inputs {
  display: grid;
  margin-top: 0;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.volo-PassengerDetails-input-container {
  background-color: var(--color-gray);
  padding: 10px 20px;
  border-radius: 8px;
}

.volo-PassengerDetails-header {
  font-size: 13px;
  font-color: var(--color-darkgray);
  font-weight: 700;
}

.volo-PassengerDetails-input {
  width: 100%;
  background-color: var(--color-gray);
  font-size: 20px;
  border: none;
}
.volo-PassengerDetails-tooltip {
  color: var(--color-accent);
  display: inline;
}
.volo-PassengerDetails-preferences {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  text-align: center;
  grid-column-gap: 10px;
}

.volo-PassengerDetails-preference-selected {
  color: white;
  background-color: var(--color-accent);
  border-radius: 8px;
  border: 2px solid var(--color-accent);
  cursor: pointer;
  width: 100%;
  padding: 8px 1vw;
}
.volo-PassengerDetails-preference {
  border-radius: 8px;
  border: 2px solid var(--color-darkgray);
  width: 100%;
  padding: 8px 1vw;
  cursor: pointer;
  color: var(--color-darkgray);
}

.volo-PassengerDetails-error {
  color: red;
  font-size: 12px;
}

@media only screen and (max-width: 900px) {
  .volo-PassengerDetails-inputs {
    grid-template-columns: 1fr;
  }
}
