.volo-Locations {
  border-radius: 8px;
  padding: 5px 20px;
  background-color: var(--color-white);
  margin-bottom: 20px;
  margin-top: 20px;
}

@media only screen and (max-width: 900px) {
  .volo-Locations {
  	margin-bottom: 20px;
  }
}
