.volo-Admin {
  display: grid;
  overflow-x: scroll;
  border-radius: 8px;
  color: var(--color-primarytext);
  background-color: white;
  padding: 1vw 2vw 2vw 2vw;
}

table {
  width: 100%;
}
