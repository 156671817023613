.volo-SelectCharter {
  grid-column: 1/3;
}

.volo-SelectCharter-Contact-message {
  font-size: 13px;
  text-align: center;
  margin-top: 2vh;
  color: var(--color-darkgray);
}

.volo-SelectCharter-Contact-link {
  text-decoration: none !important;
  padding-left: 0.5vw;
  color: var(--color-accent);
}

a:hover {
  text-decoration: none !important;
}

.volo-SelectCharter-Contact-link:hover {
  color: var(--color-accent) !important;
  opacity: .6;
}

.volo-SelectCharter-count {
  margin: 30px 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
}
