.volo-BookingTypeSelect {
  display: grid;
  align-items: center;
  justify-content: start;
  grid-template-columns: auto auto auto auto;
}

.volo-BookingTypeSelect-text {
  font-size: 30px;
  font-weight: 600;
}

/*.volo-BookingTypeSelect-dropdown {*/
  /*margin-left: 5px;*/
  /*font-size: 20px;*/
  /*margin-right: 5px;*/
  /*background-color: var(--color-gray);*/
  /*border-radius: 26px;*/
  /*color: var(--color-primarytext);*/
/*}*/

.Dropdown-menu {
    background-color: white;
    border: 0;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 8px;
    max-height: 260px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.Dropdown-option.is-selected {
    background-color: #f2f9fc;
    color: #333;
}
.Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}
.Dropdown-option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
}
.Dropdown-root {
    position: relative;
    margin-right: 24px;
    margin-left: 16px;
    height:48px;
}
.Dropdown-control {
    position: relative;
    overflow: hidden;
    padding: 13px 16px 13px 16px;
    display: inline-block;
    width: 100%;
    background-color: #f4f4f4;
    font-size: 17px;
    border-radius: 26px;
    box-sizing: border-box;
    color: var(--color-primarytext);
    cursor: pointer;
    outline: none;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    margin-left: 5px;
    margin-right: 20px;
}
.Dropdown-control:hover {
  filter: brightness(.95);
}

.Dropdown-arrow {
    border-color: #242A30 transparent transparent;
    border-style: solid;
    border-width: 6px 6px 0;
    content: ' ';
    display: block;
    height: 0;
    position: absolute;
    right: 16px;
    top: 22px;
    width: 0;
}

@media only screen and (max-width: 900px) {
  .volo-BookingTypeSelect {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }
  .Dropdown-root {
    margin:0 0 10px 0;

  }
  .volo-BookingTypeSelect {
    margin-top:20px;
    text-align: center;
  }
}
