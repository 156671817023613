.volo-About-navigation a {
  font-size: 20px;
  margin-right: 16px;
  line-height: 60px;
  text-decoration: none;
}

.volo-About-about {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding:32px;
  font-size: 20px;
  font-weight: 500;
  color: var(--color-primarytext);
}

.volo-About-about ul {
  display: grid;
  grid-gap: 16px;
  width: calc(100% - 24px);
  grid-template-columns: 50% 50%;
  color: #444;
  text-align: left;
  font-weight: 700;
  list-style: none;
  padding-inline-start: 0px;
}
.volo-About-about ul.volo-About-biography {
  display: grid;
  grid-gap: 64px;
  width: calc(100% - 64px);
  grid-template-columns: 60% 40%;
  color: #444;
  text-align: left;
  font-weight: 700;
  list-style: none;
  padding-inline-start: 0px;
}
.volo-About-about ul.volo-About-biography p {
  color: var(--color-primarytext);
  margin-top: 0px;
}
.volo-About-about ul.volo-About-biography li {
  background-color: transparent;
  overflow: hidden;
  border-radius: 8px;
  font-weight: 500;
  padding: 0px;
  font-size: 17px;
  line-height: 20px;
  overflow: hidden;
  word-wrap: break-word;
}
.volo-About-volologo {
  background-image: url('../../../myAssets/images/volologo.jpg');
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}
.volo-About-about li {
  background-color: var(--color-gray);
  border-radius: 8px;
  font-weight: 500;
  padding: 32px;
  font-size: 17px;
  line-height: 20px;
  overflow: hidden;
  word-wrap: break-word;
}
h1 {
  margin: 0 0 30px 0;
}
h4 {
  margin: 0 0 30px 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
}

@media only screen and (max-width: 900px) {
  h1 {
    margin: 30px 0;
  }
  .volo-About-about ul {
    width: 100%;
    grid-template-columns: 100%;
  }
  .volo-About-about ul.volo-About-biography {
    width:100%;
    grid-template-columns: 100%;
  }
}

