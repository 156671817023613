.volo-Header {
  height: 92px;
}

.volo-Header-navigation {
  height: 60px;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.volo-Header-link {
  font-size: 20px;
  cursor: pointer;
  margin-left: 25px;
}
.volo-Header-link:hover {
  opacity: .8;
}

.volo-Header-logo {
  background-image: url('../../../myAssets/images/logo.svg');
  background-size: 60px 60px;
  cursor: pointer;
  height: 60px;
  width: 60px;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 900px) {
  .volo-Header {
    height: 60px;
  }
}
