.volo-Summary {
  background-color: var(--color-gray);
  border-radius: 8px;
  height: fit-content;
  padding: 0 20px 20px 20px;
  margin: 0 0 0 20px;
  grid-column: 2/3;
}

.volo-Summary-icon {
  grid-row: 1/3;
  font-size: 24px;
  justify-self: start;
  align-self: center;
}

.volo-ChaterDetails-value {
  word-wrap: break-word;
  word-break: keep-all;
}

.volo-Summary-value span {
  font-weight: 500;
}
.volo-Summary-text {
  margin-bottom: 10px;
}

.volo-Summary-subtitle {
  font-size: 13px;
  line-height: 14px;
  color: var(--color-darkgray);
}

.volo-Summary-title {
  color: var(--color-primarytext);
  font-weight: 700;
  font-size: 30px;
  margin: 30px 0;
}

.volo-Summary-container {
  background-color: var(--color-gray);
  border-radius: 8px;
  padding: 8px 0;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 32px 1fr;
}

.volo-Summary-container-single {
  background-color: var(--color-gray);
  border-radius: 8px;
  padding: 8px 0;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr;
}

.volo-Summary-logo {
  object-fit: scale-down;
  height: 4vh;
  grid-row: 1/3;
  align-self: center;
  justify-self: start;
  border-radius: 50%;
}

@media only screen and (max-width: 900px) {
  .volo-Summary {
    margin: 20px 0 0 0;
    padding-top: 1px;
  }
}
