.volo-Payment {
  margin-bottom: 10px;
  display: grid;
  grid-gap: 2vh;
  align-items: center;
}

.volo-Payment button {
  padding: 14px;
  font-size: 20px;
  border-radius: 52px;
  outline: none;
  font-weight: 700;
}

.volo-Payment-creditcard {
  background-color: var(--color-accent);
  border-color: transparent;
  color: white;
}

.volo-Payment-ApplePay {
  display: none;
}

.volo-Payment-ApplePay div iframe {
  border-radius: 52px;
}

.volo-Payment-apple-icon {
  margin-bottom: 0.5vh;
  font-size: 20px;
}

.volo-Payment-paypal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F7C64B;
}

.volo-Payment-paypal-icon {
  height: 2.5vh;
  margin-left: 5px;
}
