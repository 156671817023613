.volo-Home-button {
  border-radius: 52px;
  height: 76px;
  font-size: 30px;
  font-weight: 600;
  cursor: pointer;
  outline: none !important;
  background-color: var(--color-accent);
  color: var(--color-gray);
  border: 1px solid transparent;
  padding: 20px 50px 20px 50px;
  width: 60%;
  margin-top: 5vh;
  margin-right: 20%;
  margin-left: 20%;
}

.volo-Home-button.disabled {
  background-color: var(--color-bg);
  border: 1px solid #999999;
  opacity: .5;
}

.volo-Home-button.disabled:hover {
  cursor: not-allowed;
}

@media only screen and (max-width: 900px) {
  .volo-Home-button {
    width: 100%;
    margin-top: 2vh;
    margin-right: 0;
    margin-left: 0;
  }
}
