.volo-CharterDetails {
  grid-column: 1/3;
}

.volo-CharterDetails-destination {
  font-size: 30px;
  line-height: 32px;
  margin:30px 0;
}

.volo-CharterDetails-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 0;
}

.volo-CharterDetails-grid-detail {
  background-color: var(--color-gray);
  border-radius: 8px;
  padding: 10px;
  display: grid;
  grid-template-columns: 42px 1fr;
  grid-column-gap: 10px;
  margin-top: 0;
}

.volo-CharterDetails-icon {
  grid-row: 1/3;
  font-size: 24px;
  justify-self: center;
  align-self: center;
}

.volo-ChaterDetails-value {
  word-wrap: break-word;
  word-break: break-all;
}
.volo-CharterDetails-subtitle {
  font-size: 13px;
  line-height: 14px;
  color: var(--color-darkgray);
}

.volo-CharterDetails-title {
  color: var(--color-primarytext);
  font-weight: 700;
  font-size: 30px;
  margin: 30px 0;
}

.volo-CharterDetails-container {
  background-color: var(--color-gray);
  border-radius: 8px;
  padding: 10px;
  display: grid;
  align-items: center;
  grid-column-gap: 10px;
  grid-template-columns: 42px 1fr;
}

.volo-CharterDetails-container-options {
  background-color: var(--color-gray);
  border-radius: 8px;
  padding: 10px 20px;
  margin-bottom: 2vh;
}

.volo-ChaterDetails-options {
  width: 100%;
  font-size: 20px;
  background-color: var(--color-gray);
  border: none;
  outline: none !important;
}

.volo-CharterDetails-logo {
  object-fit: scale-down;
  height: 42px;
  border-radius: 50%;
  grid-row: 1/3;
}

@media only screen and (max-width: 900px) {
  .volo-CharterDetails-grid {
    grid-template-columns: 1fr;
  }
}
