.volo-ErrorDialog {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0,0,0,0.7);
  width: 100%;
}

.volo-ErrorDialog-container {
  position: absolute;
  top: 34%;
  left: 10vw;
  line-height: 4vh;
  background-color: white;
  padding: 20px;
  border-radius: 18px;
  width: 80vw;
  text-align: center;
}

.volo-ErrorDialog-message {
  font-size: 20px;
  color: var(--color-darkgray);
  margin-bottom: 30px;
}

.volo-ErrorDialog-title {
  font-size: 20px;
  color: black;
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  font-weight: 700;
  margin: 10px 0 30px 0;
}

.volo-ErrorDialog-button {
  background-color: red;
  color: white;
  line-height: 24px;
  width: 100%;
  padding: 14px;
  font-size: 20px;
  border-radius: 52px;
  outline: none;
  font-weight: 700;
}
