.volo-CharterOption {
  margin-bottom: 20px;
  background-color: var(--color-gray);
  border-radius: 8px;
  padding: 2vw;
  display: grid;
  grid-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 2fr 1.5fr auto;
  justify-content: space-between;
  padding: 20px;
}

.volo-CharterOption-name {
  align-items: center;
  display: flex;
}

.volo-CharterOption-title {
  font-size: 13px;
  color: var(--color-secondarytext);
  line-height: 17px;
}

.volo-CharterOption-value {
  font-size: 20px;
  color: var(--color-primarytext);
  font-weight: 700;
}
.volo-CharterOption-priceper {
  font-size: 13px;
  line-height: 17px;
  color: var(--color-secondarytext);
  font-weight: 500;
}

.volo-CharterOption-logo {
  object-fit: scale-down;
  height: 8vh;
  border-radius: 50%;
  margin-right: 20px;
}

.volo-CharterOption-extra {
  line-height: 5vh;
  color: var(--color-secondarytext);
  display: flex;
  align-items: center;
  font-size: 17px;
}

.volo-CharterOption-extra span {
 font-weight: 700;
 padding-right: 0.2vw;
}

.volo-CharterOption-price {
  padding-bottom: 0;
  height: 60px;
  display: flex;
  align-items: center;
}

.volo-CharterOption-icon {
   margin: 1vh;
   height: 4vh;
   width: 4vh;
}
.volo-CharterOption-values {
  height: auto;
  align-self: center;
}
.volo-CharterOption-selected {
  cursor: pointer;
  text-align: center;
  background-color: white;
  color: var(--color-accent);
  font-size: 20px;
  font-weight: 700;
  border-radius: 36px;
  padding: 14px 40px;
  align-self: center;
}

.volo-CharterOption-select {
  cursor: pointer;
  text-align: center;
  background-color: var(--color-accent);
  font-weight: 700;
  color: white;
  font-size: 20px;
  border-radius: 36px;
  padding: 14px 40px;
  align-self: center;
}

@media only screen and (max-width: 900px) {
  .volo-CharterOption {
    grid-template-rows: auto auto auto;
    grid-template-columns: 100%;
    grid-gap: 10px;
    justify-content: space-between
  }

  .volo-CharterOption-values {
    display: grid;
  }
}
