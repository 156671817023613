.volo-Quote {
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-radius: 8px;
  color: var(--color-primarytext);
  background-color: white;
  padding: 20px;
}

.volo-Quote-Logo {
  object-fit: scale-down;
  width: 60px;
  cursor: pointer;
}

.volo-Quote-Header {
  display: flex;
  grid-row: 1;
  grid-column: 1/3;
  align-items: center;
  margin-bottom: 10px;
}

.volo-Quote-Title {
  font-size: 20px;
  margin-left: 20px;
}

.volo-Quote-controls {
  grid-column: 1/3;
  display: grid;
  margin-top: 10px;
  grid-gap: 10px;
  width: 100%;
  text-align: center;
  grid-template-columns: 1fr 1fr;
}

.volo-Quote-next {
  width: 100%;
  background-color: var(--color-accent);
  border: none;
  font-size: 20px;
  outline: none !important;
  border-radius: 52px;
  padding: 14px;
  color: white;
  font-weight: 700;
}

.volo-Quote-back {
  width: 100%;
  background-color: var(--color-gray);
  border: none;
  font-size: 20px;
  outline: none !important;
  border-radius: 52px;
  padding: 14px;
  font-weight: 700;
}

@media only screen and (max-width: 900px) {
  .volo-Quote {
    display: block;
  }
}
